<template>
  <div class="StatusPageItem-wrapper">
    <div class="StatusPageItem">
      <div class="container-fluid">
        <div class="row align-items-center justify-content-sm-between">
          <div>
            <BadgeMonitorLabel :monitor="badge.monitor"></BadgeMonitorLabel>
          </div>

          <div class="d-flex justify-content-end status">
            <BadgePageActions :badge="badge" @delete="$emit('delete')"/>
          </div>
        </div>
        <div class="badge-item-wrapper">
          <div class="row align-items-center justify-content-sm-between badge-item-info">
            <div class="badge-item-info-item">
              <div class="badge-item-title">status</div>
              <div :class="{
                'badge-item-status-active': badge.monitor_status === 'Connected',
                'badge-item-status-inactive': badge.monitor_status === 'Inactive',
               }">
                {{ badge.status }}
              </div>
            </div>
            <div class="badge-item-info-item">
              <div class="badge-item-title">updated</div>
              <div class="badge-item-date">
                {{ updated_at }}
              </div>
            </div>
            <div class="badge-item-info-item">
              <div class="badge-item-title">created</div>
              <div class="badge-item-date">
                {{ created_at }}
              </div>
            </div>
          </div>
        </div>
        <div class="badge-item-preview-wrapper">
          <div :id="'badge-preview-' + badge.id"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BadgePageActions from './BadgePageActions.vue'
import { Role } from '@/_helpers/role.js'
import BadgeMonitorLabel from '@/components/Badge/BadgeMonitorLabel.vue'
import moment from 'moment'
import BadgeLib from '@/entities/badge-lib.js'

export default {
  components: {
    BadgePageActions,
    BadgeMonitorLabel
  },

  props: {
    badge: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      role: Role,
      apiLink: process.env.VUE_APP_BASE_URL
    }
  },

  mounted () {
    BadgeLib.init({
      badge: this.badge,
      element_id: 'badge-preview-' + this.badge.id,
      mode: this.badgeMode
    })
  },

  computed: {
    badgeMode () {
      if (this.apiLink === 'https://api.pulsetic.com/') {
        return 'production'
      } else if (this.apiLink === 'https://dev.pulsetic.com/') {
        return 'dev'
      } else if (this.apiLink === 'http://localhost:3400/') {
        return 'local'
      }
      return 'dev'
    },
    created_at () {
      return moment(this.badge.created_at).format('MMMM DD, YYYY')
    },
    updated_at () {
      let types = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'second']
      for (let i = 0; i < types.length; i++) {
        let diff = moment().diff(moment(this.badge.updated_at), types[i])
        if (i === types.length - 1 && !diff) {
          return '1 second ago'
        }
        if (diff) {
          return diff + ' ' + (diff === 1 ? types[i].slice(0, -1) : types[i]) + ' ago'
        }
      }
      return ''
    },
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
.StatusPageItem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  //border: 1px solid rgb(212, 218, 231);
  box-shadow: 0 2px 4px rgba(25,34,29,0.05);
  border-radius: 6px;
  background-color: #fff;
  transition: box-shadow 200ms ease 0s, border-color 200ms ease 0s;
  padding: 12px 20px 20px;

  .badge-item {
    &-preview {
      &-wrapper {
        margin: 25px -15px 0;
        display: flex;
      }
    }
    &-status {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.022em;
      &-active {
        @extend .badge-item-status;
        color: #219653;
      }
      &-inactive {
        @extend .badge-item-status;
        color: #F2994A;
      }
    }
    &-info {
      margin-top: 20px;
      &-item {
        width: 30%;
      }
      &-item:last-child {
        width: 40%;
      }
    }
    &-title {
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.05em;
      color: #1B1823;
      opacity: 0.6;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    &-date {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.022em;
      color: #1B1823;
    }
  }

  &-wrapper {
    width: 50%;
    padding: 8px;
    @media (max-width: 990px) {
      width: 100%;
    }
  }

  .view-status-page {
    @include outlined-link;
  }

  .status {
    @media (max-width: 990px) {
      display: none !important;
    }

    &-mobile {
      display: none !important;
      @media (max-width: 990px) {
        display: flex !important;
      }
    }
  }

  .public-link {
    display: inline-flex;
    align-items: center;

    @media (max-width: 990px) {
      width: 100%;
      justify-content: space-between;

      a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-wrapper {
      width: 66.7%;

      @media (max-width: 990px) {
        flex: 0 0 58.33333% !important;
        max-width: 58.33333% !important;
      }

      @media (max-width: 767px) {
        flex: 0 0 66.33333% !important;
        max-width: 66.33333% !important;
      }
    }

    &:hover .copy {
      opacity: 1;
    }

    .copy {
      opacity: 0;
      margin-left: 10px;
      line-height: 1;
      padding: 5px 6px 4px;
      font-size: 12px;
      font-weight: 600;
      color: map-get($colors, gray-1);
      cursor: pointer;
      text-transform: uppercase;
      background: #f6f5f7;
      border-radius: 2px;
      line-height: 1;
      margin-bottom: 1px;
      transition: 0.2s all;

      @media (max-width: 990px) {
        display: none;
      }

      @media (max-width: 767px) {
        color: map-get($colors, purple-1);
      }

      &:hover {
        color: map-get($colors, purple-1);
      }
    }
  }
}
</style>
