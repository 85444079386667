export default {
  async save (data, id) {
    const response = await axios.post(`monitors/${id}/badge`, data)
    return response.data
  },
  async list () {
    try {
      const response = await axios.get(`monitors/badge/index`)
      return response.data
    } catch {
      return false
    }
  },
  async get (id) {
    try {
      const response = await axios.get(`monitors/${id}/badge`)
      return response.data
    } catch {
      return false
    }
  },
  async delete (id) {
    const response = await axios.delete(`monitors/${id}/badge`)
    return response.data
  }
}
