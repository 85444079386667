<template>
  <div class="StatusPagesList">
    <StatusBadgeOnboarding v-if="user.badges_number === 0" />

    <template v-if="user.badges_number > 0">
      <div class="top">
        <div class="page-header">
          Status Badges
        </div>
        <div class="right" v-if="user.role !== role.Viewer && user.role !== role.Accountant">
          <base-button color="primary"
                       ref="newStatusPageButton"
                       class="addNew"
                       @click="goToNewBadgePage"
                       :icon="isMobile ? 'IconAdd' : null"
          >
            {{ !isMobile ? 'Create Badge' : '' }}
          </base-button>
        </div>
      </div>

      <BadgePageList @set-badges-length="badgesLength = $event" />

      <div v-show="false">
        <hint-limit-exceeded ref="limitExceeded" />
      </div>
    </template>
  </div>
</template>

<script>
import BadgePageList from '@/components/Badge/BadgePageList.vue'
import StatusBadgeOnboarding from '../../components/Badge/StatusBadgeOnboarding'
import HintLimitExceeded from '@/components/Hints/HintStatusPagesLimitExceeded.vue'
import { Role } from '@/_helpers/role.js'

import tippy from 'tippy.js'

export default {
  components: {
    BadgePageList,
    StatusBadgeOnboarding,
    HintLimitExceeded
  },

  metaInfo: {
    title: 'Status Badges'
  },

  data () {
    return {
      tippy: null,
      role: Role,
      badgesLength: null
    }
  },

  methods: {
    goToNewBadgePage () {
      this.$router.push({
        name: 'BadgePage',
        params: {
          id: 0
        }
      })
    },

    showTippy () {
      if (this.tippy) {
        this.tippy.destroy()
      }

      this.tippy = tippy(this.$refs.newStatusPageButton.$el, {
        allowHTML: true,
        content: this.$refs.limitExceeded.$el,
        interactive: true,
        arrow: false,
        placement: 'bottom-end',
        trigger: 'manual',
        theme: 'big-padding',
        animation: 'shift-away-extreme'
      })

      this.tippy.show()
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ]),
    isMobile () {
      return window.innerWidth < 767
    }
  }
}
</script>

<style lang="scss" scoped>
.StatusPagesList {
  display: flex;
  flex-direction: column;
  flex: 1 0;

  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .addNew {
    ::v-deep {
      svg {
        margin-right: 0 !important;
      }
    }
  }
}
</style>
