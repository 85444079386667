<template>
  <div>
    <item-placeholder-list v-if="loading"/>
    <div class="StatusPageList">
      <BadgePageItem
        v-for="badge in badges"
        :key="badge.id"
        :badge="badge"
        @delete="loadStatusBadges"
      />
    </div>
  </div>
</template>

<script>
import ItemPlaceholderList from '@/components/Common/ItemPlaceholderList.vue'
import BadgePageItem from './BadgePageItem.vue'
import badgeApi from '@/api/badgeApi.js'

export default {
  components: {
    ItemPlaceholderList,
    BadgePageItem
  },

  data () {
    return {
      badges: [],
      loading: false
    }
  },

  created () {
    this.loadStatusBadges()
  },

  methods: {
    // TODO: Is it possible to refactor this pattern?
    async loadStatusBadges () {
      this.loading = true
      this.badges = []
      try {
        let badges = await badgeApi.list()
        this.badges = badges.map(item => {
          if (!item.status) {
            item.status = 'Inactive'
          }
          return item
        })
        this.$emit('set-badges-length', this.badges.length)
        this.loading = false
      } catch (error) {
        this.$emit('set-badges-length', 0)
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .StatusPageList {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
    margin-bottom: 15px;
    .header {
      margin-bottom: 10px;
      color: #999ca7;
    }
    .status-title {
      white-space: nowrap;
      width: 30px;
    }
  }
</style>
