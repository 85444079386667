<template>
  <div v-if="monitor" class="MonitorLabel">
    <a :href="monitor.url"
       class="favicon"
       target="_blank"
       v-if="showFavicon">
      <img :src="monitor.url | favicon" />
    </a>
    <router-link :to="monitorRoute"
                 class="title font-weight-5"
                 :class="titleClasses"
                 v-if="labelIsLink">{{ monitor | monitorNameOrUrl }}</router-link>
    <template v-else>
      <span class="title"
            :class="titleClasses">
        {{ monitor | monitorNameOrUrl }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    monitor: {
      required: true,
      type: Object
    },

    labelIsLink: {
      required: false,
      type: Boolean,
      default: true
    },

    showFavicon: {
      required: false,
      type: Boolean,
      default: true
    }
  },

  computed: {
    monitorRoute () {
      return {
        name: 'BadgePage',
        params: {
          id: this.monitor.id
        }
      }
    },
    titleClasses () {
      return [
        { 'ml-2': this.showFavicon }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorLabel {
    display: inline-flex;
    align-items: center;

    @include truncate;

    .favicon {
      height: 16px;
      width: 16px;

      flex-shrink: 0;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      @include truncate;
    }
  }
</style>
